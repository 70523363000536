<template>
	<div>
		<div class="title">
			模板中心
		</div>
		<div class="search-panel">
			<a-row :gutter="16">
				<a-col :span="6">
					<a-input placeholder="模板名称/模板描述" v-model="name" allowClear></a-input>
				</a-col>
				<a-col :span="6">
					<a-cascader allowClear v-model="industryArr"
						:field-names="{ label: 'name', value: 'id', children: 'children' }"
						:options="industrySectorList" placeholder="请选择行业领域" style="width: 100%;" />
				</a-col>
				<a-col :span="6">
					<a-select placeholder="请选择实验类型" v-model="experimentType" allowClear style="width: 100%;">
						<a-select-option v-for="(item, index) in dataList" :value="item.value" :key="index">
							{{ item.text }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="6">
					<a-button type="primary" class="search-btn" @click="handleSearch">查询</a-button>
					<a-button class="reset-btn" @click="resetSearch"> 重置 </a-button>
				</a-col>
			</a-row>
		</div>
		<a-spin :spinning="loading" tip="正在加载中...">
			<sTable :columns="columns" :data="data" :pagination.sync="pagination" @change="change"
				:rollHeight="tableHeight">
				<template slot="name" slot-scope="data">
					<a-tooltip placement="topLeft">
						<template slot="title">
							<span>{{ data.record.name }}</span>
						</template>
						<div style="width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
							{{ data.record.name }}
						</div>
					</a-tooltip>
				</template>
				<template slot="description" slot-scope="data">
					<a-tooltip placement="topLeft">
						<template slot="title">
							<span>{{ data.record.description }}</span>
						</template>
						<div style="width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
							{{ data.record.description }}
						</div>
					</a-tooltip>
				</template>
				<template slot="operation" slot-scope="data">
					<a :href="getPreviewUrl(data.record)" target="_blank"> 预览 </a>
					<a style="margin-left: 16px;" @click="handleApply(data.record)"> 应用 </a>
				</template>
			</sTable>
		</a-spin>
	</div>
</template>

<script>
import { message, Modal } from 'ant-design-vue';
import { getExperimentTemplateInitData } from '../../../api/experimentTemplate'
import { applyTemplate, getShareExperimentTemplateList } from '../../../api/shareExperimentTemplate'
import sTable from '../../../components/sTable.vue';
const tableHeight = document.documentElement.clientHeight - 480;
export default {
	components: {
		sTable
	},
	data() {
		return {
			columns: [
				{
					title: '实验模板名称',
					dataIndex: 'name',
					scopedSlots: {
						customRender: 'name'
					},
				},
				{
					title: '作者',
					dataIndex: 'userName'
				},
				{
					title: '分享时间',
					dataIndex: 'createdTime'
				},
				{
					title: '行业领域',
					dataIndex: 'industrySectorName'
				},
				{
					title: '实验类型',
					dataIndex: 'experimentTypeName'
				},
				{
					title: '模板描述',
					dataIndex: 'description',
					scopedSlots: {
						customRender: 'description'
					},
				},
				{
					title: '应用次数',
					dataIndex: 'applicationTimes'
				},
				{
					title: '操作',
					dataIndex: 'operation',
					key: 'operation',
					width: 150,
					fixed: "right",
					scopedSlots: { customRender: "operation" }
				}
			],
			data: [
			],
			pagination: {
				total: 0,
				defaultPageSize: 10,
				showTotal: total => `共 ${total} 条数据`,
				showSizeChanger: true,
				pageSizeOptions: ['5', '10', '15', '20'],
				current: 1,
				pageSize: 10
			},
			industrySectorList: [],
			dataList: [],
			name: undefined,	// 模板名称/模板描述
			industryArr: [], // 选择的行业领域
			experimentType: undefined, 	// 实验类型
			loading: false,
			tableHeight
		}
	},
	methods: {
		change(data) {
			this.pagination.current = data.current;
			this.pagination.pageSize = data.pageSize;
			this.getShareTemplateList();
		},
		handleSearch() {
			this.getShareTemplateList();
		},
		resetSearch() {
			this.industryArr = [];
			this.experimentType = undefined;
			this.name = '';
		},
		async getShareTemplateList() {
			this.loading = true;
			const res = await getShareExperimentTemplateList({
				Name: this.name,
				ExperimentType: this.experimentType,
				IndustrySector: this.industryArr.length > 0 ? this.industryArr.join(',') : undefined,
				PageNo: this.pagination.current,
				PageSize: this.pagination.pageSize
			});
			if (res.code === 200) {
				this.data = res.data.rows;
				this.pagination.total = res.data.totalRows;
				this.loading = false;
			}
		},
		getPreviewUrl(template) {
			const href = this.$router.resolve({
				name: 'experimentTemplate',
				query: {
					shareId: template.id,
					mode: 'preview'
				}
			});
			return href.href;
		},
		handleApply(template) {
			Modal.confirm({
				title: '提示',
				content: '是否应用该模板到我的模板？',
				onOk: async () => {
					const res = await applyTemplate({
						Id: template.id
					});
					if (res.code === 200 || res.code === 204) {
						message.success(res.message);
						this.getShareTemplateList();
					}
				}
			})
		}
	},
	async mounted() {
		const initRes = await getExperimentTemplateInitData();
		if (initRes.code === 200) {
			this.industrySectorList = initRes.data.industrySectorList;
			this.dataList = initRes.data.dataList;
		}
		this.getShareTemplateList();
	}
}
</script>

<style lang="less" scoped>
/deep/ .sTable .ant-table {
	min-height: calc(100vh - 520px);
}

/deep/ .ant-table-fixed-header .ant-table-body-inner {
	overflow: auto;
}
</style>

<style scoped>
.title {
	font-size: 18px;
	color: rgba(0, 0, 0, 0.85);
	text-align: left;
	border-bottom: 1px solid #EBEBEB;
	margin: 0 16px;
	padding: 16px 0;
	margin-bottom: 16px;
	font-weight: 550
}

.search-panel {
	padding: 0px 16px;
	margin-bottom: 16px;
	text-align: left;
}


.reset-btn {
	background: #eeeeee;
	margin-left: 8px;
}
</style>
