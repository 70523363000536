// 模板中心
import  request from "../utils/request";

// 分享中心
export const getShareExperimentTemplateList = (params) => {
    return request({
        method: 'get',
        url: `/shareExperimentTemplate/page`,
        params
    })
}

// 应用模板
export const applyTemplate = (params) => {
    return request({
        method: 'get',
        url: `/shareExperimentTemplate/applyTemplate`,
        params
    })
}

// 获取实验模板详情
export const getShareExperimentTemplateDetail = (params) => {
    return request({
        method: 'get',
        url: `/shareExperimentTemplate/detail`,
        params
    })
}

// 应用模板
export const applyShareTemplate = (params) => {
    return request({
        method: 'get',
        url: '/shareExperimentTemplate/applyTemplate',
        params
    })
}